import React, { useEffect, useState } from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import DownloadGuides from "../components/home-sections/DownloadGuides"
import Thankyou from "../components/thankyou/thankyouSection"
import MainLayout from "../layouts/MainLayout"
import { navigate } from "gatsby"

const ThankyouPage = ({ location }) => {
  const [isNavigate, setIsNavigate] = useState(false)

  useEffect(() => {
    if (location.state && location.state.pathname) {
      setIsNavigate(true)
    } else {
      navigate("/")
    }
  }, [location.state])

  return isNavigate ? (
    <MainLayout bgChanged={false}>
      <Thankyou pathname={location.state.pathname} />
      <DownloadGuides p={true} />
    </MainLayout>
  ) : null
}

export default ThankyouPage

export const Head = () => {
  return (
    <SEORevamp
      title="Thank You"
      description="Global Software Development Outsourcing Company. Hire InvoZone offshore developers team and tech experts for web and product development."
    />
  )
}
