import React from "react"
import Container from "react-bootstrap/Container"
import thanks_icon from "../../images/icons/thanks_icon.svg"
import * as styles from "./thankyouSection.module.scss"
import { Link } from "gatsby"

const Thankyou = ({ pathname }) => {
  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.container}>
          <div>
            <img
              decoding="async"
              loading="lazy"
              src={thanks_icon}
              alt="thank-you"
              width="70px"
            />
            <h1 className="main-heading-36 text-light">
              Thank You For Reaching Out
            </h1>
            <p className="text-light font-weight-normal">
              One of our representatives will get back to you at the earliest.
            </p>
            <div className="mt-5 d-sm-flex d-xs-grid justify-content-around">
              <Link to={"/"} className={`mb-3 ${styles.btn_white_bg}`}>
                Go to Home Page
              </Link>
              <Link to={pathname} className={`mb-3 ${styles.btn_white_bg}`}>
                Back to Previous Page
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Thankyou
